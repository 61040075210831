import {iuvpaaSupportLevel, webauthnSupportLevel} from '../webauthn-support-level'
import {changeValue} from '../form'
// eslint-disable-next-line no-restricted-imports
import {observe} from 'selector-observer'

// Record the browser's webauthn support level in the GitHub login form.
// Ask the device to sign a request when the user taps its button.
observe('.js-webauthn-support', {
  constructor: HTMLInputElement,
  add(el) {
    changeValue(el, webauthnSupportLevel())
  }
})

observe('.js-webauthn-iuvpaa-support', {
  constructor: HTMLInputElement,
  async add(el) {
    changeValue(el, await iuvpaaSupportLevel())
  }
})
